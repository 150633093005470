<template>
    <div>
        <b-button variant="success" class="btn-icon" size="sm" style="padding: 5px;"
            v-b-tooltip.hover.top="'Ajouter au panier'" @click="params.onButtonClick(params.data)">
            <feather-icon icon="ShoppingCartIcon" size="15" />
        </b-button>
    </div>
</template>

<script>

export default {
};
</script>

<style scoped></style>
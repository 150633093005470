<template>
    <div id="products-list">
        <b-overlay :show="showLoading" spinner-variant="primary">

            <b-modal hide-footer ref="item-modal" title="Détails de l'article" size="xl" scrollable>

                <ProductDetailsPARA v-if="selected.type == 'PARA'" :product="selected" />
                <ProductDetailsMED v-else :product="selected" />

            </b-modal>


            <b-card>
                <b-row>
                    <b-col cols="12" md="5">
                        <b-tabs pills nav-class="nav-pill-PRIMARY">
                            <b-tab title="MEDICAMENTS" active @click="medClicked" />
                            <b-tab title="PARA-MEDICAMENTS" @click="paraClicked" />
                        </b-tabs>
                    </b-col>
                    <b-col cols="12" md="7">
                        <b-form-input @input="updateSearchQuery" v-model="searchQuery" placeholder="Recherche"
                            type="text" class="d-inline-block" />
                    </b-col>
                </b-row>
                <ag-grid :agGrid="agGrid" ref="agGrid" pageTitle="Liste des articles" @refresh="loadData"
                    @cellDoubleClicked="cellDoubleClicked" :showHeader="false" />
            </b-card>

        </b-overlay>
    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import ProductDetailsMED from "@/views/product/ProductDetailsMED.vue";
import ProductDetailsPARA from "@/views/product/ProductDetailsPARA.vue";
import AddToCartCellRenderer from "@/views/product/AddToCartCellRenderer.vue";


export default {

    metaInfo() {
        return {
            title: "Liste des produits - PharmaConnect",
            meta: [
                {
                    vmid: "description",
                    name: "description",
                    content:
                        "Explorez la liste complète des produits pharmaceutiques et parapharmaceutiques sur PharmaConnect, des informations détaillées sur les médicaments, les para-médicaments.",
                },
                {
                    name: "robots",
                    content: "index, follow",
                },
                {
                    property: "og:title",
                    content: "Liste des produits - PharmaConnect",
                },
                {
                    property: "og:description",
                    content:
                        "Explorez la liste complète des produits pharmaceutiques et parapharmaceutiques sur PharmaConnect. Trouvez des informations détaillées sur les médicaments, les para-médicaments, les DCI, et les codes PCT.",
                },
                {
                    property: "og:image",
                    content: "https://www.pharmaconnect.net/images/products-list-thumbnail.jpg",
                },
                {
                    property: "og:url",
                    content: "https://www.pharmaconnect.net/products-list",
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    name: "twitter:card",
                    content: "summary_large_image",
                },
                {
                    name: "twitter:title",
                    content: "Liste des produits - PharmaConnect",
                },
                {
                    name: "twitter:description",
                    content:
                        "Explorez la liste complète des produits pharmaceutiques et parapharmaceutiques sur PharmaConnect. Trouvez des informations détaillées sur les médicaments, les para-médicaments, les DCI, et les codes PCT.",
                },
                {
                    name: "twitter:image",
                    content: "https://www.pharmaconnect.net/images/products-list-thumbnail.jpg",
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: "https://www.pharmaconnect.net/products-list",
                },
            ],
            script: [
                // Structured Data using Schema.org
                {
                    type: "application/ld+json",
                    json: {
                        "@context": "https://schema.org",
                        "@type": "WebPage",
                        "name": "Liste des produits - PharmaConnect",
                        "description": "Explorez la liste complète des produits pharmaceutiques et parapharmaceutiques sur PharmaConnect. Trouvez des informations détaillées sur les médicaments, les para-médicaments, les DCI, et les codes PCT.",
                        "brand": {
                            "@type": "Brand",
                            "name": "PharmaConnect"
                        }
                    },
                },
            ],
        };
    },

    components: {
        AgGrid,
        ProductDetailsMED,
        ProductDetailsPARA
    },
    data() {
        return {
            selected: {},
            searchQuery: "",
            MEDcolumnDefs: [{
                headerName: "Description",
                field: "description",
                cellRendererFramework: 'addToCartCellRenderer',
                cellRendererParams: { onButtonClick: this.cellDoubleClicked, addToCart: this.addToCart },
                cellClass: 'product-addToCart-customeCellRender'
            },
            {
                headerName: "DCI",
                field: "dciDescription",
            },
            {
                headerName: "Laboratoire",
                field: "vendorDescription",
                colId: "vendorDescriptionMed"
            },
            {
                headerName: "TVA",
                field: "vat",
                colId: "vatMed"
            },
            {
                headerName: "Cartonnage",
                field: "packaging",
                colId: "packagingMed"
            },
            {
                headerName: "Barcode",
                field: "barCode",
                colId: "barCodeMed"
            },
            ],
            PARAcolumnDefs: [
                {
                    headerName: "Description",
                    field: "description",
                    cellRendererFramework: 'addToCartCellRenderer',
                    cellRendererParams: { onButtonClick: this.cellDoubleClicked, addToCart: this.addToCart },
                    cellClass: 'product-addToCart-customeCellRender'
                },
                {
                    headerName: "Fournisseur",
                    field: "vendorDescription",
                    colId: "vendorDescriptionPARA"
                },
                {
                    headerName: "Famille",
                    field: "familyDescription",
                },
                {
                    headerName: "Sous-Famille",
                    field: "subFamilyDescription",
                },
                {
                    headerName: "TVA",
                    field: "vat",
                    colId: "vatPARA"
                },
                {
                    headerName: "Cartonnage",
                    field: "packaging",
                    colId: "packagingPARA"
                },
                {
                    headerName: "Barcode",
                    field: "barCode",
                    colId: "barCodePARA"
                },
            ],
            showLoading: false,
            agGrid: {
                columnDefs: [],
                rows: []
            },
        }
    },
    computed: {
        tableData() {
            return this.$store.getters["app-items/geItemsMED"];
        },
    },
    watch: {
        tableData(products) {
            if (products.length > 0) {
                this.showLoading = false;
                this.agGrid.rows = [...products]
                    .sort((a, b) => {
                        const dateA = new Date(`${a.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${a.updatedAt.split(' - ')[1]}`);
                        const dateB = new Date(`${b.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${b.updatedAt.split(' - ')[1]}`);
                        return dateB - dateA;
                    });
                this.$nextTick(() => {
                    if (!/Mobi/i.test(navigator.userAgent))
                        this.updateColumnsWidth();
                });
            }
        },
    },
    created() {
        this.$options.components.addToCartCellRenderer = AddToCartCellRenderer;
        if (this.$store.getters["app-items/geItemsMED"].length > 0) {
            this.agGrid.columnDefs = [...this.MEDcolumnDefs];
            setTimeout(() => {
                this.agGrid.rows = this.$store.getters["app-items/geItemsMED"]
                    .sort((a, b) => {
                        const dateA = new Date(`${a.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${a.updatedAt.split(' - ')[1]}`);
                        const dateB = new Date(`${b.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${b.updatedAt.split(' - ')[1]}`);
                        return dateB - dateA;
                    });
                this.$nextTick(() => {
                    if (!/Mobi/i.test(navigator.userAgent))
                        this.updateColumnsWidth();
                });
            }, 800);
        }
    },
    methods: {
        medClicked() {
            this.searchQuery = "";
            this.showLoading = true;
            this.$refs.agGrid.gridOptions.columnDefs = [];
            this.$refs.agGrid.gridOptions.columnDefs = [...this.MEDcolumnDefs];
            this.agGrid.rows = this.$store.getters["app-items/geItemsMED"]
                .sort((a, b) => {
                    const dateA = new Date(`${a.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${a.updatedAt.split(' - ')[1]}`);
                    const dateB = new Date(`${b.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${b.updatedAt.split(' - ')[1]}`);
                    return dateB - dateA;
                });
            this.showLoading = false;
            this.$nextTick(() => {
                if (!/Mobi/i.test(navigator.userAgent))
                    this.updateColumnsWidth();
            });
        },
        paraClicked() {
            this.searchQuery = "";
            this.showLoading = true;
            // this.agGrid.columnDefs = [...this.PARAcolumnDefs];
            this.$refs.agGrid.gridOptions.columnDefs = [];
            this.$refs.agGrid.gridOptions.columnDefs = [...this.PARAcolumnDefs];
            this.agGrid.rows = this.$store.getters["app-items/geItemsPARA"]
                .sort((a, b) => {
                    const dateA = new Date(`${a.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${a.updatedAt.split(' - ')[1]}`);
                    const dateB = new Date(`${b.updatedAt.split(' - ')[0].split('-').reverse().join('-')}T${b.updatedAt.split(' - ')[1]}`);
                    return dateB - dateA;
                });
            this.$nextTick(() => {
                if (!/Mobi/i.test(navigator.userAgent))
                    this.updateColumnsWidthPara();
            });
            this.showLoading = false;
        },
        async showDetails(data) {
            this.selected = data;
            this.$refs['item-modal'].show();
        },
        loadData() {
            this.showLoading = true;
            this.$store.dispatch('app-items/FETCH_ITEMS_FROM_BACKEND');
        },
        updateSearchQuery(val) {
            const gridApi = this.$refs.agGrid.gridApi;
            gridApi.setQuickFilter(val);
        },
        cellDoubleClicked(param) {
            this.showDetails(param.data ? param.data : param);
        },
        updateColumnsWidth() {
            const viewportWidth = this.$refs.agGrid.$el.offsetWidth; // Get the viewport width

            // Define your percentages for each column
            const widths = {
                description: 40,
                dciDescription: 17,
                vendorDescription: 17,
                vat: 6,
                packaging: 9,
                barCode: 11,
            };

            // Calculate pixel widths based on percentages
            const newColumnDefs = this.$refs.agGrid.gridOptions.columnDefs.map(colDef => {
                const widthPercentage = widths[colDef.field];
                if (widthPercentage) {
                    const widthPx = Math.floor((viewportWidth * widthPercentage) / 100);
                    return { ...colDef, width: widthPx };
                }
                return colDef; // Return original definition if no percentage is defined
            });

            this.$refs.agGrid.gridOptions.api.setColumnDefs(newColumnDefs);
        },
        updateColumnsWidthPara() {
            const viewportWidth = this.$refs.agGrid.$el.offsetWidth; // Get the viewport width

            // Define your percentages for each column
            const widths = {
                description: 40,
                vendorDescription: 12,
                familyDescription: 12,
                subFamilyDescription: 12,
                vat: 6,
                packaging: 7,
                barCode: 11,
            };

            // Calculate pixel widths based on percentages
            const newColumnDefs = this.$refs.agGrid.gridOptions.columnDefs.map(colDef => {
                const widthPercentage = widths[colDef.field];
                if (widthPercentage) {
                    const widthPx = Math.floor((viewportWidth * widthPercentage) / 100);
                    return { ...colDef, width: widthPx };
                }
                return colDef; // Return original definition if no percentage is defined
            });

            this.$refs.agGrid.gridOptions.api.setColumnDefs(newColumnDefs);
        },
        addToCart(param) {
            this.$emit('addToCart', param);
        },
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>
<template>
    <div>
        <div v-if="params.data.code != ''">
            <b-link style="border-bottom: 1px solid ;" v-b-tooltip.hover.top="'Articles de Substitution'" href="#"
                @click="params.onButtonSubClick(params.data)">
                <feather-icon icon="GitPullRequestIcon" size="18" />
            </b-link>

            <b-avatar class="mr-1 ml-1"
                v-if="params.data.defImage == undefined || params.data.defImage == null || params.data.defImage == ''"
                size="25"
                :src="params.data.type == 'MED' ? require('@/assets/images/PillMED.svg') : require('@/assets/images/PillPARA.svg')"
                :text="avatarText(params.data.description)"
                :variant="params.data.type == 'MED' ? 'light-success' : 'light-primary'"
                @click="$emit('showDetails', params.data)" />
            <b-avatar class="mr-1 ml-1" v-else size="25" :src="$serverpath + params.data.defImage"
                :text="avatarText(params.data.description)"
                :variant="params.data.type == 'MED' ? 'light-success' : 'light-primary'"
                @click="$emit('showDetails', params.data)" />

            <b-link href="#" class="font-weight-bold text-primary" style="border-bottom: 1px solid ;"
                @click="params.onButtonClick(params.data)">
                {{ params.data.description == undefined ? params.data.Description : params.data.description }}
            </b-link>
        </div>
    </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
export default {
    data() {
        return {
            avatarText,
        }
    },
};
</script>

<style scoped></style>
<template>
    <div>
        <b-row>
            <b-col class="text-left">
                <b-avatar class="mr-1"
                    v-if="params.data.defImage == undefined || params.data.defImage == null || params.data.defImage == ''"
                    size="25"
                    :src="params.data.type == 'MED' ? require('@/assets/images/PillMED.svg') : require('@/assets/images/PillPARA.svg')"
                    :text="avatarText(params.data.description)"
                    :variant="params.data.type == 'MED' ? 'light-success' : 'light-primary'"
                    @click="$emit('showDetails', params.data)" />
                <b-avatar class="mr-1" v-else size="25" :src="$serverpath + params.data.defImage"
                    :text="avatarText(params.data.description)"
                    :variant="params.data.type == 'MED' ? 'light-success' : 'light-primary'"
                    @click="$emit('showDetails', params.data)" />

                <b-link href="#" class="font-weight-bold text-primary" style="border-bottom: 1px solid ;"
                    @click="params.onButtonClick(params.data)">
                    {{ params.data.description == undefined ? params.data.Description.length > 47 ?
                        params.data.Description.substr(0, 45) + ".." : params.data.Description :
                        params.data.description.length >
                        47 ? params.data.description.substr(0, 45) + ".." : params.data.description }}
                </b-link>
            </b-col>
            <b-col class="text-right">

                <b-button variant="success" class="btn-icon" size="sm" style="padding: 5px;"
                    v-b-tooltip.hover.top="'Ajouter au panier'" @click="params.addToCart(params.data)">
                    <feather-icon icon="ShoppingCartIcon" size="15" />
                </b-button>

            </b-col>
        </b-row>
    </div>
</template>

<script>
import { avatarText } from '@core/utils/filter'
export default {
    data() {
        return {
            avatarText,
        }
    },
};
</script>

<style scoped></style>
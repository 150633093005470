<template>
    <div>
        <!-- NewOrder -->
        <div v-if="params.componentName == 'NewOrder'">
            <div v-if="params.data.code != ''">
                <feather-icon class="text-success" size="20" icon="CheckCircleIcon" v-if="params.data.state" />
                <feather-icon class="text-danger" size="20" icon="XCircleIcon" v-else />
            </div>
        </div>
        <!-- NewOrder -->

        <!-- Actuality -->
        <div v-if="params.componentName == 'Actuality'">
            <feather-icon class="text-danger" size="20" icon="XCircleIcon" />
        </div>
        <!-- Actuality -->
    </div>
</template>
  
<script>

export default {
};
</script>
  
<style scoped></style>
  
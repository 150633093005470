<template>
    <div id="products-list">
        <b-overlay :show="showLoading" spinner-variant="primary">

            <b-card>
                <b-row>
                    <b-col cols="8">
                        <b-form-input @input="updateSearchQuery" v-model="searchQuery" size="sm" placeholder="Recherche"
                            type="text" class="d-inline-block" />
                    </b-col>
                </b-row>

                <ag-grid :agGrid="agGrid" ref="agGrid" @cellDoubleClicked="cellDoubleClicked" :showHeader="false" />

            </b-card>
        </b-overlay>
    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import { formatPrice } from "@/utils/formatting";
import AddToCartCellRenderer from "./AddToCartCellRenderer.vue";
import BLinkCellRenderer from "@/views/components/ag-grid/BLinkCellRenderer.vue"

export default {

    metaInfo() {
        return {
            title: "Liste des Nouveautés - PharmaConnect",
            meta: [
                {
                    vmid: "description",
                    name: "description",
                    content:
                        "Découvrez les nouvelles arrivées de produits pharmaceutiques et parapharmaceutiques chez nos partenaires fournisseurs sur PharmaConnect. Trouvez les informations sur les prix et les disponibilités des dernières nouveautés.",
                },
                {
                    name: "robots",
                    content: "index, follow",
                },
                {
                    property: "og:title",
                    content: "Liste des Nouveautés - PharmaConnect",
                },
                {
                    property: "og:description",
                    content: "Explorez les dernières nouveautés de produits pharmaceutiques et parapharmaceutiques disponibles chez nos fournisseurs avec toutes les informations nécessaires sur les prix et la disponibilité.",
                },
                {
                    property: "og:image",
                    content: "https://www.pharmaconnect.net/images/nouveautes-thumbnail.jpg",
                },
                {
                    property: "og:url",
                    content: "https://www.pharmaconnect.net/nouveautes",
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    name: "twitter:card",
                    content: "summary_large_image",
                },
                {
                    name: "twitter:title",
                    content: "Liste des Nouveautés - PharmaConnect",
                },
                {
                    name: "twitter:description",
                    content: "Découvrez les nouveaux produits pharmaceutiques disponibles sur PharmaConnect avec des détails sur les fournisseurs, les prix HT et les prix publics.",
                },
                {
                    name: "twitter:image",
                    content: "https://www.pharmaconnect.net/images/nouveautes-thumbnail.jpg",
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: "https://www.pharmaconnect.net/nouveautes",
                },
            ],
            script: [
                // Structured Data using Schema.org
                {
                    type: "application/ld+json",
                    json: {
                        "@context": "https://schema.org",
                        "@type": "ProductCollection",
                        "name": "Liste des Nouveautés - PharmaConnect",
                        "description": "Explorez les nouveautés de produits pharmaceutiques et parapharmaceutiques disponibles chez nos fournisseurs, incluant les informations de prix et de fournisseurs.",
                        "brand": {
                            "@type": "Brand",
                            "name": "PharmaConnect"
                        }
                    },
                },
            ],
        };
    },



    components: {
        AgGrid,
    },
    data() {
        return {
            searchQuery: "",
            showLoading: true,
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Description",
                        field: "Description",
                        cellRendererFramework: 'bLinkCellRenderer',
                        cellRendererParams: { onButtonClick: this.cellDoubleClicked, }
                    },
                    {
                        headerName: "Type",
                        field: "Gen_Prod_Posting_Group",
                    },
                    {
                        headerName: "Fournisseur",
                        field: "Vendor_Name",
                    },
                    {
                        headerName: "Prix H.T",
                        field: "Unit_Price",
                        valueGetter: function (tr) {
                            return formatPrice(tr.data.Unit_Price);
                        },
                    },
                    {
                        headerName: "Prix T.T.C",
                        field: "Price_Including_VAT",
                        valueGetter: function (tr) {
                            return formatPrice(tr.data.Price_Including_VAT);
                        },
                    },
                    {
                        headerName: "Prix Public",
                        field: "Public_Price",
                        valueGetter: function (tr) {
                            return formatPrice(tr.data.Public_Price);
                        },
                    },
                    {
                        sortable: false,
                        resizable: false,
                        filter: false,
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'addToCartCellRenderer',
                        cellRendererParams: { onButtonClick: this.addToCart },
                        headerName: '',
                        field: "addToCart"
                    },
                ],
                rows: []
            },
        }
    },
    async created() {
        this.$options.components.bLinkCellRenderer = BLinkCellRenderer;
        this.$options.components.addToCartCellRenderer = AddToCartCellRenderer;
        const response = await this.$http.get("novelty");
        this.agGrid.rows = response.data;
        this.$nextTick(() => {
            if (!/Mobi/i.test(navigator.userAgent))
                this.updateColumnsWidth();
        });
        this.showLoading = false;
    },
    methods: {
        updateSearchQuery(val) {
            this.$refs.agGrid.gridApi.setQuickFilter(val);
        },
        cellDoubleClicked(param) {
            this.$emit('itemClicked', param.data ? param.data : param);
        },
        addToCart(param) {
            this.$emit('addToCart', param);
        },
        updateColumnsWidth() {
            const viewportWidth = this.$refs.agGrid.$el.offsetWidth; // Get the viewport width

            // Define your percentages for each column
            const widths = {
                Description: 30,
                Gen_Prod_Posting_Group: 12,
                Vendor_Name: 20,
                Unit_Price: 10,
                Price_Including_VAT: 10,
                Public_Price: 10,
                addToCart: 8,
            };

            // Calculate pixel widths based on percentages
            const newColumnDefs = this.$refs.agGrid.gridOptions.columnDefs.map(colDef => {
                const widthPercentage = widths[colDef.field];
                if (widthPercentage) {
                    const widthPx = Math.floor((viewportWidth * widthPercentage) / 100);
                    return { ...colDef, width: widthPx };
                }
                return colDef; // Return original definition if no percentage is defined
            });

            this.$refs.agGrid.gridOptions.api.setColumnDefs(newColumnDefs);
        },
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>
<template>
    <div id="products-list">
        <b-overlay :show="showLoading" spinner-variant="primary">

            <b-card>
                <b-row>
                    <b-col cols="8">
                        <b-form-input @input="updateSearchQuery" v-model="searchQuery" size="sm" placeholder="Recherche"
                            type="text" class="d-inline-block" />
                    </b-col>
                </b-row>

                <ag-grid :agGrid="agGrid" ref="agGrid" @cellDoubleClicked="cellDoubleClicked" :showHeader="false" />

            </b-card>
        </b-overlay>
    </div>
</template>

<script>

import AgGrid from "@/views/components/ag-grid/AgGrid.vue"
import BadgeCellRenderer from "@/views/components/ag-grid/BadgeCellRendererPromotion.vue";
import { formatPrice } from "@/utils/formatting";
import AddToCartCellRenderer from "./AddToCartCellRenderer.vue";
import BLinkCellRenderer from "@/views/components/ag-grid/BLinkCellRenderer.vue"

export default {

    metaInfo() {
        return {
            title: "Liste des Promotions - PharmaConnect",
            meta: [
                {
                    vmid: "description",
                    name: "description",
                    content:
                        "Découvrez les promotions en cours sur les produits pharmaceutiques et parapharmaceutiques disponibles chez nos fournisseurs partenaires sur PharmaConnect. Profitez des meilleures offres.",
                },
                {
                    name: "robots",
                    content: "index, follow",
                },
                {
                    property: "og:title",
                    content: "Liste des Promotions - PharmaConnect",
                },
                {
                    property: "og:description",
                    content: "Explorez les promotions actuelles sur les produits pharmaceutiques et parapharmaceutiques disponibles chez nos fournisseurs avec des informations sur les prix et les offres spéciales.",
                },
                {
                    property: "og:image",
                    content: "https://www.pharmaconnect.net/images/promotions-thumbnail.jpg",
                },
                {
                    property: "og:url",
                    content: "https://www.pharmaconnect.net/promotions",
                },
                {
                    property: "og:type",
                    content: "website",
                },
                {
                    name: "twitter:card",
                    content: "summary_large_image",
                },
                {
                    name: "twitter:title",
                    content: "Liste des Promotions - PharmaConnect",
                },
                {
                    name: "twitter:description",
                    content: "Découvrez les meilleures promotions sur les produits pharmaceutiques et parapharmaceutiques sur PharmaConnect, avec les détails des fournisseurs et des prix.",
                },
                {
                    name: "twitter:image",
                    content: "https://www.pharmaconnect.net/images/promotions-thumbnail.jpg",
                },
            ],
            link: [
                {
                    rel: "canonical",
                    href: "https://www.pharmaconnect.net/promotions",
                },
            ],
            script: [
                // Structured Data using Schema.org
                {
                    type: "application/ld+json",
                    json: {
                        "@context": "https://schema.org",
                        "@type": "OfferCatalog",
                        "name": "Liste des Promotions - PharmaConnect",
                        "description": "Découvrez les offres et promotions en cours sur les produits pharmaceutiques et parapharmaceutiques chez nos fournisseurs partenaires.",
                        "brand": {
                            "@type": "Brand",
                            "name": "PharmaConnect"
                        }
                    },
                },
            ],
        };
    },


    components: {
        AgGrid,
    },
    data() {
        return {
            searchQuery: "",
            showLoading: true,
            startDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 2).toISOString().split('T')[0],
            endDate: new Date().toISOString().split('T')[0],
            agGrid: {
                columnDefs: [
                    {
                        headerName: "Description",
                        field: "Description",
                        cellRendererFramework: 'bLinkCellRenderer',
                        cellRendererParams: { onButtonClick: this.cellDoubleClicked, }
                    },
                    {
                        headerName: "Type",
                        field: "Gen_Prod_Posting_Group",
                    },
                    {
                        headerName: "Fournisseur",
                        field: "Vendor_Name",
                    },
                    {
                        headerName: "Prix H.T",
                        field: "Unit_Price",
                        valueGetter: function (tr) {
                            return formatPrice(tr.data.Unit_Price);
                        },
                    },
                    {
                        headerName: "Prix Public",
                        field: "Public_Price",
                        valueGetter: function (tr) {
                            return formatPrice(tr.data.Public_Price);
                        },
                    },
                    {
                        headerName: "Promotion",
                        field: 'Sales_threshold',
                        cellRendererFramework: 'badgeCellRenderer',
                    },
                    {
                        sortable: false,
                        resizable: false,
                        filter: false,
                        cellClass: 'centered-customeCellRender',
                        cellRendererFramework: 'addToCartCellRenderer',
                        cellRendererParams: { onButtonClick: this.addToCart },
                        headerName: '',
                        field: "addToCart"
                    },
                ],
                rows: []
            },
        }
    },
    async created() {
        this.$options.components.bLinkCellRenderer = BLinkCellRenderer;
        this.$options.components.addToCartCellRenderer = AddToCartCellRenderer;
        this.$options.components.badgeCellRenderer = BadgeCellRenderer;
        // const response = await this.$http.get("promotion");
        let response = await this.$http.post("promotion/findWhere", this.generateDateFilters());
        // console.log(response);
        this.agGrid.rows = response.data.filter(item => item.Inventory >= (item.Free_Sales_Qty + item.Sales_threshold));
        this.$nextTick(() => {
            if (!/Mobi/i.test(navigator.userAgent))
                this.updateColumnsWidth();
        });
        this.showLoading = false;
    },
    methods: {
        generateDateFilters() {
            const filters = [
                {
                    "field": "Creation_Date",
                    "value": "",
                    "operator": "gt"
                },
                {
                    "field": "Creation_Date",
                    "value": "",
                    "operator": "lt"
                }
            ];
            // Convert start date string to Date and adjust by -1 day
            const startDateObj = new Date(this.startDate);
            startDateObj.setDate(startDateObj.getDate() - 1);
            // Convert end date string to Date and adjust by +1 day
            const endDateObj = new Date(this.endDate);
            endDateObj.setDate(endDateObj.getDate() + 1);
            // Format adjusted dates as strings in yyyy-MM-dd format
            const formattedStartDate = startDateObj.toISOString().split('T')[0];
            const formattedEndDate = endDateObj.toISOString().split('T')[0];
            // Set adjusted date values in the filter objects
            filters[0].value = formattedStartDate + "T00:00:00Z";
            filters[1].value = formattedEndDate + "T00:00:00Z";
            return filters;
        },
        updateSearchQuery(val) {
            this.$refs.agGrid.gridApi.setQuickFilter(val);
        },
        cellDoubleClicked(param) {
            this.$emit('itemClicked', param.data ? param.data : param);
        },
        addToCart(param) {
            this.$emit('addToCart', param);
        },
        updateColumnsWidth() {
            const viewportWidth = this.$refs.agGrid.$el.offsetWidth; // Get the viewport width

            // Define your percentages for each column
            const widths = {
                Description: 30,
                Gen_Prod_Posting_Group: 12,
                Vendor_Name: 20,
                Unit_Price: 10,
                Sales_threshold: 10,
                Public_Price: 10,
                addToCart: 8,
            };

            // Calculate pixel widths based on percentages
            const newColumnDefs = this.$refs.agGrid.gridOptions.columnDefs.map(colDef => {
                const widthPercentage = widths[colDef.field];
                if (widthPercentage) {
                    const widthPx = Math.floor((viewportWidth * widthPercentage) / 100);
                    return { ...colDef, width: widthPx };
                }
                return colDef; // Return original definition if no percentage is defined
            });

            this.$refs.agGrid.gridOptions.api.setColumnDefs(newColumnDefs);
        },
    }
}
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .card {
        background-color: initial;
        box-shadow: initial;
    }

    .card-body {
        padding: 0px;
    }
}
</style>